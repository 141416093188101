import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.service';

import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';

import { FullLayout_ROUTES } from './shared/routes/full-layout.routes';
import { CommonLayout_ROUTES } from './shared/routes/common-layout.routes';
import { PageNotFoundComponent } from './shared/template/page-not-found/page-not-found.component';
import { JwtModule } from '@auth0/angular-jwt';

// tslint:disable-next-line:typedef
export function tokenGetter() {
  return JSON.parse(localStorage.getItem('authuser')).Token;
}

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard/home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canActivate: [AuthGuard]
    }, {
        path: '',
        component: FullLayoutComponent,
        children: FullLayout_ROUTES
    },
    {path: '**', component: PageNotFoundComponent}
];

@NgModule({
    imports: [

    RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        }),
        JwtModule.forRoot({
          config: {
            tokenGetter,
            allowedDomains: ['https://localhost:7030', 'http://audit.codekitexpress.com', 'http://oditkit.com'],
            disallowedRoutes: []
          }
        })
    ],
    exports: [
        RouterModule
    ],
    providers: [AuthGuard],
})

export class AppRoutingModule {
}
