import {Routes} from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'teams',
    loadChildren: () => import('../../team-management/team-management.module').then(m => m.TeamManagementModule),
  },
  {
    path: 'audit-center',
    loadChildren: () => import('../../audit-center-management/audit-center-management.module').then(m => m.AuditCenterManagementModule),
  },
  {
    path: 'audit-resources',
    loadChildren: () => import('../../audit-resource-management/audit-resource-management.module').then(m => m.AuditResourceManagementModule),
  },
  {
    path: 'audit-task',
    loadChildren: () => import('../../audit-tasks-management/audit-tasks-management.module').then(m => m.AuditTasksManagementModule),
  },
  {
    path: 'users',
    loadChildren: () => import('../../users-management/users-management.module').then(m => m.UsersManagementModule),
  },
  {
    path: 'department',
    loadChildren: () => import('../../department-management/department-management.module').then(m => m.DepartmentManagementModule),
  },
  {
    path: 'audit',
    loadChildren: () => import('../../audit-management/audit-management.module').then(m => m.AuditManagementModule),
  }, {
    path: 'audit-plan',
    loadChildren: () => import('../../audit-plan-management/audit-plan-management.module').then(m => m.AuditPlanManagementModule),
  }, {
    path: 'audit-plan',
    loadChildren: () => import('../../audit-plan-management/audit-plan-management.module').then(m => m.AuditPlanManagementModule),
  }, {
    path: 'audit-working-paper',
    loadChildren: () => import('../../audit-working-paper-management/audit-working-paper-management.module').then(m => m.AuditWorkingPaperManagementModule),
  }, {
    path: 'shared',
    loadChildren: () => import('../../shared/shared.module').then(m => m.SharedModule),
  },
];
