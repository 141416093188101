import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {ThemeConstantService} from './services/theme-constant.service';
import {SearchPipe} from './pipes/search.pipe';
import {TemplateSection} from './models/templatesection.model';
import {AppsService} from './services/apps.service';
import {AuthenticationService} from './services/authentication.service';
import {RandomUserService} from './services/random-user.service';
import {TableService} from './services/table.service';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzRateModule} from 'ng-zorro-antd/rate';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzTimelineModule} from 'ng-zorro-antd/timeline';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzCalendarModule} from 'ng-zorro-antd/calendar';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {NzAutocompleteModule} from 'ng-zorro-antd/auto-complete';
import {NzBackTopModule} from 'ng-zorro-antd/back-top';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzCascaderModule} from 'ng-zorro-antd/cascader';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzCommentModule} from 'ng-zorro-antd/comment';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzDescriptionsModule} from 'ng-zorro-antd/descriptions';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzI18nModule} from 'ng-zorro-antd/i18n';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMentionModule} from 'ng-zorro-antd/mention';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzMessageModule} from 'ng-zorro-antd/message';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzNoAnimationModule} from 'ng-zorro-antd/core/no-animation';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {NzResultModule} from 'ng-zorro-antd/result';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzSliderModule} from 'ng-zorro-antd/slider';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzStatisticModule} from 'ng-zorro-antd/statistic';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import {NzTransButtonModule} from 'ng-zorro-antd/core/trans-button';
import {NzTransferModule} from 'ng-zorro-antd/transfer';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {NzTreeSelectModule} from 'ng-zorro-antd/tree-select';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzWaveModule} from 'ng-zorro-antd/core/wave';
import {NzResizableModule} from 'ng-zorro-antd/resizable';
import {NgChartjsModule} from 'ng-chartjs';
import {ToastrModule} from 'ngx-toastr';
import {CalendarminilistComponent} from '../widgets/calendarminilist/calendarminilist.component';
import {InputComponent} from '../widgets/input/input.component';
import {QuillModule} from 'ngx-quill';

const antdModule = [
  NzButtonModule,
  NzCardModule,
  NzAvatarModule,
  NzRateModule,
  NzBadgeModule,
  NzProgressModule,
  NzRadioModule,
  NzTableModule,
  NzDropDownModule,
  NzTimelineModule,
  NzTabsModule,
  NzTagModule,
  NzListModule,
  NzCalendarModule,
  NzToolTipModule,
  NzCheckboxModule,
  NzAffixModule,
  NzAlertModule,
  NzAnchorModule,
  NzAutocompleteModule,
  NzAvatarModule,
  NzBackTopModule,
  NzBadgeModule,
  NzButtonModule,
  NzBreadCrumbModule,
  NzCardModule,
  NzCarouselModule,
  NzCascaderModule,
  NzCollapseModule,
  NzCommentModule,
  NzDatePickerModule,
  NzDescriptionsModule,
  NzDividerModule,
  NzDrawerModule,
  NzDropDownModule,
  NzEmptyModule,
  NzFormModule,
  NzGridModule,
  NzI18nModule,
  NzIconModule,
  NzInputModule,
  NzInputNumberModule,
  NzLayoutModule,
  NzListModule,
  NzMentionModule,
  NzMenuModule,
  NzMessageModule,
  NzModalModule,
  NzNoAnimationModule,
  NzNotificationModule,
  NzPageHeaderModule,
  NzPaginationModule,
  NzPopconfirmModule,
  NzProgressModule,
  NzRadioModule,
  NzRateModule,
  NzResultModule,
  NzSelectModule,
  NzSkeletonModule,
  NzSliderModule,
  NzSpinModule,
  NzStatisticModule,
  NzStepsModule,
  NzSwitchModule,
  NzTableModule,
  NzTabsModule,
  NzTagModule,
  NzTimePickerModule,
  NzTimelineModule,
  NzToolTipModule,
  NzTransButtonModule,
  NzTransferModule,
  NzTreeModule,
  NzTreeSelectModule,
  NzTypographyModule,
  NzUploadModule,
  NzWaveModule,
  NzResizableModule,
  NzMessageModule
];

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    // PerfectScrollbarModule,
    SearchPipe,
    NgChartjsModule, // required animations module
    ReactiveFormsModule,
    ...antdModule,
    InputComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    ToastrModule.forRoot(),
    // PerfectScrollbarModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    ...antdModule,
  ],
  declarations: [
    SearchPipe,
    CalendarminilistComponent,
    InputComponent
  ],
  providers: [
    ThemeConstantService,
    AppsService,
    TableService,
    TemplateSection,
    AuthenticationService,
    RandomUserService,
  ]
})

export class SharedModule {
}
