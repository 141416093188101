<div class="header">
  <div class="logo logo-dark">
    <a href="">
        <img src="https://www.codekitexpress.com/wp-content/uploads/2021/11/CodeKit-Express-Color-Logo-300x53.png" width="210px" style="margin-top: 10px;" alt="Logo">
        <img class="logo-fold" src="https://www.codekitexpress.com/wp-content/uploads/2021/11/CodeKit-Express-Color-Logo-300x53.png" height="40px" style="margin: 10px" alt="Logo">
    </a>
</div>
<div class="logo logo-white">
    <a href="">
        <img src="https://www.codekitexpress.com/wp-content/uploads/2021/11/Logo-Default-White.png" width="210px" style="margin-top: 10px;" alt="Logo">
        <img class="logo-fold" src="https://www.codekitexpress.com/wp-content/uploads/2022/03/CodeKitExpress-All-White_Icon.png" height="40px" style="margin: 10px;" alt="Logo">
    </a>
</div>
  <div class="nav-wrap">
      <ul class="nav-left">
          <li class="desktop-toggle">
              <a (click)="toggleFold()">
                  <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
              </a>
          </li>
          <li class="mobile-toggle">
              <a (click)="toggleExpand()">
                  <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
              </a>
          </li>
          <li>
              <a (click)="searchToggle()">
                  <i nz-icon nzType="search" theme="outline"></i>
              </a>
              <nz-drawer [nzWidth]="380" nzTitle="Search" [nzVisible]="searchVisible" nzPlacement="left" (nzOnClose)="searchToggle()">
                  <app-search></app-search>
              </nz-drawer>
          </li>
      </ul>
      <ul class="nav-right">
          <li>
              <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="popNotification" [nzPlacement]="'bottomRight'">
                  <nz-badge nzDot>
                      <i nz-icon nzType="bell" theme="outline"></i>
                  </nz-badge>
              </a>
              <nz-dropdown-menu #popNotification="nzDropdownMenu">
                  <div nz-menu class="pop-notification">
                      <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                          <p class="text-dark font-weight-semibold m-b-0">
                              <i nz-icon nzType="bell" theme="outline"></i>
                              <span class="m-l-10">Notification</span>
                          </p>
                          <a nz-button nzType="link" nzSize="small" class="p-v-5" [routerLink]="''">
                              <small>View All</small>
                          </a>
                      </div>
<!--                      <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">-->
                          <nz-list class="ant-list-item-links" [nzDataSource]="notificationList" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                              <ng-template #item let-item>
                                  <nz-list-item>
                                      <a [routerLink]="''">
                                          <div class="d-flex">
                                              <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                              <div class="m-l-15">
                                                  <p class="m-b-0 text-dark">{{item.title}}</p>
                                                  <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                              </div>
                                          </div>
                                      </a>
                                  </nz-list-item>
                              </ng-template>
                          </nz-list>
<!--                      </perfect-scrollbar>-->
                  </div>
              </nz-dropdown-menu>
          </li>
          <li>
              <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                  <nz-avatar [nzIcon]="'user'" nzSrc="{{userdetails.ProfileImage}}"></nz-avatar>
              </span>
              <nz-dropdown-menu #profile="nzDropdownMenu">
                  <ul nz-menu class="p-b-15 p-t-20">
                      <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                          <div class="d-flex m-r-50">
                              <nz-avatar nzSize="large" nzSrc="{{userdetails.ProfileImage}}"></nz-avatar>
                              <div class="m-l-10">
                                  <p class="m-b-0 text-dark font-weight-semibold">{{userdetails.Name}}</p>
                                  <p class="m-b-0 opacity-07">{{userdetails.Roles[0]}}</p>
                              </div>
                          </div>
                      </li>
                      <li nz-menu-item>
                          <a class="p-v-5 d-flex align-items-center justify-content-between">
                              <div>
                                  <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                  <span class="m-l-10">Edit Profile</span>
                              </div>
                              <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                          </a>
                      </li>
                      <li nz-menu-item>
                          <a class="p-v-5 d-flex align-items-center justify-content-between">
                              <div>
                                  <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                                  <span class="m-l-10">Account Setting</span>
                              </div>
                              <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                          </a>
                      </li>
                      <li nz-menu-item>
                          <a class="p-v-5 d-flex align-items-center justify-content-between">
                              <div>
                                  <i class="opacity-04 font-size-16" nz-icon nzType="project" theme="outline"></i>
                                  <span class="m-l-10">Projects</span>
                              </div>
                              <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                          </a>
                      </li>
                      <li nz-menu-item>
                          <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                              <div>
                                  <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                  <span class="m-l-10">Logout</span>
                              </div>
                              <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                          </a>
                      </li>
                  </ul>
              </nz-dropdown-menu >
          </li>
          <li>
              <a (click)="quickViewToggle()">
                  <i nz-icon nzType="appstore" theme="outline"></i>
              </a>
              <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                  <ng-container *nzDrawerContent>
                      <app-quick-view></app-quick-view>
                  </ng-container>
              </nz-drawer>
          </li>
      </ul>
  </div>
</div>



<!-- <div class="header">
    <div class="logo logo-dark">
        <a href="">
            <img src="https://www.codekitexpress.com/wp-content/uploads/2022/01/CodeKit-Express-Color-Logo.svg" width="210px" style="margin-top: 10px;" alt="Logo">
            <img class="logo-fold" src="https://www.codekitexpress.com/wp-content/uploads/2021/11/CodeKit-Express-Color-Icon.svg" height="50px" style="margin-top: 10px" alt="Logo">
        </a>
    </div>
    <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo">
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-right">
            <li>
                <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <ng-container *nzDrawerContent>
                        <app-quick-view></app-quick-view>
                    </ng-container>
                </nz-drawer>
            </li>
        </ul>
    </div>
</div> -->
