import { Base } from './base.model';
import { questionTemplate } from './questiontemplate.model';
import { TemplateSection } from './templatesection.model';

export class Template extends Base {
  Code: string = '';
  Title: string = '';
  Questions: questionTemplate[] = [];
  Description: string = '';
  TemplateSectionsList: TemplateSection[] = [];
  IsActive: boolean = false;
}

export interface TemplateModel extends Base {
  Code: string;
  Title: string;
  Description: string;
  Questions: questionTemplate;
  TemplateSectionsList: TemplateSection;
  IsActive: boolean;
}
