<input [formControl]=" control" [type]="type" nz-input [placeholder]="placeholder"/>
<ng-container *ngIf="control.touched && control.dirty">
  <p *ngIf="control.errors?.['required']" style="color: red">
    Field is required
    <!-- {{ registerForm.controls.name?.errors | json }} -->
  </p>
  <p *ngIf="control.errors?.['minlength']" style="color: red">
    The value you entered is
    {{ control.errors?.['minlength'].actualLength }} characters long. It must at least
    {{ control.errors?.['minlength'].requiredLength }} characters long
  </p>
  <p *ngIf="control.errors?.['email']" style="color: red">
    You must enter a valid email
  </p>
  <p *ngIf="control.errors?.['min']" style="color: red">
    Value is too low
  </p>
  <p *ngIf="control.errors?.['max']" style="color: red">
    Value is too high
  </p>
  <p *ngIf="control.errors?.['pattern']" style="color: red">
    Passwords must be at least 8 characters long, must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number
  </p>
  <p *ngIf="control.errors?.['noMatch']" style="color: red">
    Passwords do not match
  </p>
  <p *ngIf="control.errors?.['emailTaken']" style="color: red">
    Email already taken, please try another email.
  </p>
</ng-container>
