import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
  {
    path: '',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
  },
  {
    path: '',
    title: 'Working Paper Templates',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'form',
    submenu: [
      {
        path: 'audit-working-paper/questionbank',
        title: 'Question Bank',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
      {
        path: 'audit-working-paper/templates',
        title: ' Working Paper Template',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Audits',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'edit',
    submenu: [
      {
        path: 'audit/list',
        title: 'Audits List',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Audit Resources',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dollar',
    submenu: [
      {
        path: 'audit-resources',
        title: 'Manage Resource Plan',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
      {
        path: 'audit-resources/items',
        title: 'Resource Items',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },{
        path: 'audit-plan/periods',
        title: 'Audit Periods',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Audit Centers',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'home',
    submenu: [
      {
        path: 'audit-center',
        title: 'Centers List',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
      {
        path: 'audit-center/categorieslist',
        title: 'Auditable Categories',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
    ],
  },
  {
    path: 'audit-task',
    title: 'Tasks',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'check-circle',
    submenu: [],
  },
  {
    path: '',
    title: 'User Management',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'team',
    submenu: [
      {
        path: 'users',
        title: 'User List',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
      {
        path: 'users/roles',
        title: 'User Roles',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      }, {
        path: 'department',
        title: 'Departments',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      }, {
        path: 'teams',
        title: 'Teams',
        iconType: 'nzIcon',
        icon: 'right',
        iconTheme: '',
        submenu: [],
      },
    ],
  },
];
