import { Base } from './base.model';
import { Template } from './template.model';

export class TemplateSection extends Base {
  SectionName: string = '';
  SectionParent: number = 0;
  SectionHeading: string = '';
  SectionPart: string = '';
  SectionOrderNumber: number = 0;
  IsMainHeading: boolean = false;
  IsSubHeading: boolean = false;
  IsActive: boolean = false;
  TemplateId: number = 0;
  Template: Template = new Template();
}

export interface TemplateSectionModel extends Base {
  SectionName: string;
  SectionParent: number;
  SectionHeading: string;
  SectionPart: string;
  SectionOrderNumber: number;
  IsMainHeading: boolean;
  IsSubHeading: boolean;
  IsActive: boolean;
  TemplateId: number;
  Template: Template;
}
