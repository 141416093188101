<div class="container-fluid p-v-20 h-100">
  <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-none d-md-block p-h-40">
        <img src="https://www.codekitexpress.com/wp-content/uploads/2022/01/CodeKit-Express-Color-Logo.svg" width="210px" style="margin-top: 10px;" alt="Logo">
      </div>
      <div class="container">
          <div class="row align-items-center">
              <div class="col-md-5">
                  <div class="p-v-30">
                      <h1 class="font-weight-semibold display-1 text-primary lh-1-2">404</h1>
                      <h2 class="font-weight-light font-size-30">Whoops! Looks like you got lost</h2>
                      <p class="lead m-b-30">We couldnt find what you were looking for.</p>
                      <a [routerLink]="['/']" nz-button nzType="primary">Go Back</a>
                  </div>
              </div>
              <div class="col-md-6 m-l-auto">
                  <img class="img-fluid" src="assets/images/others/404.png" alt="">
              </div>
          </div>
      </div>
      <div class="d-none d-md-flex p-h-40 justify-content-between">
          <span class="">© {{currentdate | date: 'yyyy'}} Codekit Express</span>
          <ul class="list-inline">
              <li class="list-inline-item">
                  <a class="text-dark text-link" href="">Legal</a>
              </li>
              <li class="list-inline-item">
                  <a class="text-dark text-link" href="">Privacy</a>
              </li>
          </ul>
      </div>
  </div>
</div>
