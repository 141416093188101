import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthenticationService} from '../shared/services/authentication.service';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private jwtHelper: JwtHelperService
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authService.currentUserValue;
    const token = JSON.parse(localStorage.getItem('authuser'))?.Token;

    if (currentUser) {
      if (!token || this.jwtHelper.isTokenExpired(token)) {
        return this.authService.refreshToken().pipe(
          map(() => true),
          catchError((error) => {
            // Handle specific status codes or errors that indicate a failed refresh
            if (error.status === 401 || error.status === 403) {
              this.authService.logOut(); // Clear user data
              this.router.navigate(['authentication/login']);
              return of(false);
            }
            this.router.navigate(['authentication/login']);
            return of(false);
          })
        );
      }
      return true;
    }

    this.router.navigate(['authentication/login']);
    return false;
  }

}


// import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
// import { JwtHelperService } from '@auth0/angular-jwt';
//
// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGuard  {
//
//   constructor(private jwtHelper: JwtHelperService, private router: Router) {
//   }
//   canActivate() {
//     const token = JSON.parse(localStorage.getItem('authuser'))?.Token;
//
//     if (token && !this.jwtHelper.isTokenExpired(token)){
//       // console.log(this.jwtHelper.decodeToken(token));
//       return true;
//     }
//     this.router.navigate(["authentication/login"]);
//     return false;
//   }
//
// }


