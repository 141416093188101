import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, throwError} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LoginModel} from '../models/login.model';
import {environment} from 'src/environments/environment';
import {ApplicationUser} from '../models/user.model';
import {Router} from '@angular/router';

import {ResetPasswordModel} from '../models/resetpassword.model';
import {ForgotPasswordModel} from '../models/forgetpassword.model';
import {tap} from 'rxjs/operators';

const USER_AUTH_API_URL = '/api-url';

@Injectable({providedIn: 'root'})


export class AuthenticationService {
  baseUrl: string = environment.apiurl + '/Account';
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private refreshTokenTimeout: any;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('authuser')));
    ;
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  // login(username: string, password: string) {
  //     return this.http.post<any>(USER_AUTH_API_URL, { username, password })
  //     .pipe(map(user => {
  //         if (user && user.token) {
  //             localStorage.setItem('currentUser', JSON.stringify(user));
  //             this.currentUserSubject.next(user);
  //         }
  //         return user;
  //     }));
  // }


  public signIn(model: LoginModel) {
    return this.http.post<any>(this.baseUrl + '/SignIn', model);
  }

  isUserAuthenticated() {
    var user = JSON.parse(localStorage.getItem('authuser'));
    const token: string = user.Token;

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    } else {
      return false;
    }
  }

  public logOut = () => {
    localStorage.removeItem('authuser');
    // this.currentUserSubject.next(null);
    this.router.navigate(['/authentication/login']);
  };

  public getUser(): ApplicationUser {
    var storeduser = JSON.parse(localStorage.getItem('authuser'));
    let user: ApplicationUser = storeduser.user;
    return user;
  }

  public resetPassword(model: ResetPasswordModel) {
    return this.http.post<any>(this.baseUrl + '/ResetPassword', model);
  }

  public forgetPassword(model: ForgotPasswordModel) {

    return this.http.get<any>(`${this.baseUrl}/ForgotPassword/${model.Email}`);
  }

  // logout() {
  //     localStorage.removeItem('currentUser');
  //     this.currentUserSubject.next(null);
  // }

//TO DO: Implement JWT token refresh
  refreshToken() {
    return this.http.post<any>(`${this.baseUrl}/refresh-token`, {
      'RefreshToken': this.currentUserValue.RefreshToken
    }).pipe(
      tap((user) => {
        localStorage.setItem('authuser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.startRefreshTokenTimer();
      }),
      catchError(this.handleError)
    );
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.currentUserValue.token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // Handle specific errors related to authentication
    if (error.status === 401 || error.status === 403) {
      this.logOut();
    }
    return throwError(error);
  }

}
